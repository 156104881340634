import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const InternalLink = ({ city, place, object, children }) => {
  let url = `/${city.toLowerCase()}`;
  if (place) url += `/${place.data.Slug || place.data.Title}`;
  if (object) url += `/${object.data.Slug}`;

  return <Link to={url}>{children}</Link>;
};

InternalLink.propTypes = {
  city: PropTypes.string.isRequired,
  place: PropTypes.shape(),
  object: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};

InternalLink.defaultProps = {
  place: null,
  object: null,
};

export default InternalLink;
