import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Header, Segment, Button } from 'semantic-ui-react';
import Img from 'gatsby-image';
import Slider from 'react-slick';

import ObjectList from '../ObjectList/ObjectList';
import InternalLink from '../InternalLink/InternalLink';
import Lightbox from '../Lightbox/Lightbox';
import DescriptionAccordion from '../DescriptionAccordion/DescriptionAccordion';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Details.css';

const Details = ({ selectedPlace, selectedObject, cityName }) => {
  const selected = selectedObject || selectedPlace;
  const { data } = selected;
  const description = data.Object_Description || data.Place_Description;
  let descriptions = [];
  if (description) {
    const englishDescription = description.find(d => d.data.Language === 'English');
    if (englishDescription) descriptions.push(englishDescription);
    descriptions = [...descriptions, ...description.filter(d => d.data.Language !== 'English')];
    descriptions = descriptions
      .map(d => d.data.Description)
      .join('')
      .replace(/\n\s*\n/gm, '</p><p>')
      .split('</p>')
      .filter(d => d !== '</p>')
      .map(d => `${d}</p>`);
  }

  const [lightboxOpen, setLightboxOpen] = useState(false);

  let images = [];
  if (data.Image) images = data.Image.localFiles.map(i => i.childImageSharp.fullImage.src);

  return (
    <>
      {lightboxOpen && <Lightbox images={images} setLightboxOpen={setLightboxOpen} />}
      <InternalLink city={cityName}>
        <Button
          secondary
          circular
          icon="arrow left"
          style={{ position: 'absolute', zIndex: 1, top: 20, left: 20, opacity: 0.9 }}
        />
      </InternalLink>
      <Card fluid className="details">
        {data.Image && (
          <Slider dots speed={500} arrows={false} slidesToShow={1} slidesToScroll={1}>
            {data.Image.localFiles.map(img => (
              <div
                role="button"
                tabIndex={-1}
                key={img.childImageSharp.fullImage.src}
                onClick={() => setLightboxOpen(true)}
                onKeyPress={() => setLightboxOpen(true)}
                style={{ position: 'absolute' }}
              >
                <span
                  style={{
                    backgroundColor: 'black',
                    opacity: '0.6',
                    padding: '5px',
                    lineHeight: '1px',
                    borderRadius: '100%',
                    width: '23px',
                    position: 'absolute',
                    zIndex: '10',
                    right: '6px',
                    top: '6px',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="expand-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M212.686 315.314L120 408l32.922 31.029c15.12 15.12 4.412 40.971-16.97 40.971h-112C10.697 480 0 469.255 0 456V344c0-21.382 25.803-32.09 40.922-16.971L72 360l92.686-92.686c6.248-6.248 16.379-6.248 22.627 0l25.373 25.373c6.249 6.248 6.249 16.378 0 22.627zm22.628-118.628L328 104l-32.922-31.029C279.958 57.851 290.666 32 312.048 32h112C437.303 32 448 42.745 448 56v112c0 21.382-25.803 32.09-40.922 16.971L376 152l-92.686 92.686c-6.248 6.248-16.379 6.248-22.627 0l-25.373-25.373c-6.249-6.248-6.249-16.378 0-22.627z"
                      className=""
                    />
                  </svg>
                </span>
                <Img
                  fluid={img.childImageSharp.carouselImage}
                  objectPosition="50% 50%"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
          </Slider>
        )}
        <Card.Content>
          <Header as="h3">{cityName}</Header>
          <Header as="h2">{data.Title}</Header>
          {(data.Copyright || data.Source) && (
            <div style={{ marginBottom: 20 }}>
              Credit:&nbsp;
              {data.Source ? (
                <a href={data.Source}>{data.Copyright || 'Image Source'}</a>
              ) : (
                <span>{data.Copyright}</span>
              )}
            </div>
          )}
          {description && <DescriptionAccordion description={descriptions} />}
        </Card.Content>
      </Card>
      {selectedPlace && selectedPlace.data.Object && (
        <>
          <h3 style={{ margin: '35px 0px 20px 0px' }}>Objects in this Place</h3>
          <Card fluid className="details">
            <Card.Content>
              <ObjectList
                city={cityName}
                place={selectedPlace}
                object={selectedPlace.data.Object}
                displayAsCard
              />
            </Card.Content>
          </Card>
        </>
      )}

      {(data.Objects_Relationships || data.Places_Relationships) && (
        <>
          <h3 style={{ margin: '35px 0px 20px 0px' }}>
            {`Related ${selectedObject ? 'Objects' : 'Places'} from Other Cities`}
          </h3>
          <Segment>
            {selectedObject ? (
              <ObjectList object={data.Objects_Relationships} displayCity />
            ) : (
              <ObjectList place={data.Places_Relationships} displayCity />
            )}
          </Segment>
        </>
      )}
    </>
  );
};

Details.propTypes = {
  selectedPlace: PropTypes.shape(),
  selectedObject: PropTypes.shape(),
  cityName: PropTypes.string.isRequired,
};

Details.defaultProps = {
  selectedPlace: null,
  selectedObject: null,
};

export default Details;
