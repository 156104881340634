import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const DescriptionAccordion = ({ description }) => {
  const [expanded, setExpanded] = useState(false);
  let split = description;
  if (description && !Array.isArray(description)) {
    split = description.split('\n').map(d => `${d}\n`);
  }
  return (
    <>
      {parse(
        split
          .slice(0, 2)
          .join(' ')
          .replace(/\\(\/|_)/g, '$1')
      )}
      <div
        role="button"
        tabIndex="-1"
        style={{
          margin: '2rem 0',
          fontSize: 18,
          fontFamily: 'Source Sans Pro, sans-serif',
          color: 'rgb(193, 70, 70)',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
        onKeyPress={() => setExpanded(!expanded)}
      >
        {expanded ? '- Close' : '+ More / Mehr / Meer / Continua / Más / Més / 🇪🇺'}
      </div>
      {expanded &&
        parse(
          split
            .slice(2)
            .join(' ')
            .replace(/\\(\/|_)/g, '$1')
        )}
    </>
  );
};

DescriptionAccordion.propTypes = {
  description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    .isRequired,
};

export default DescriptionAccordion;
