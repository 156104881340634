/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Atlas from '../components/Atlas/Atlas';
import Sidebar from '../components/Sidebar/Sidebar';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const CityPage = props => {
  const { City_Place, Name, City_Description } = props.data.airtable.data;
  const { pathname } = props.location;

  const sidebarRef = React.createRef();

  useEffect(() => {
    sidebarRef.current.scrollIntoView();
  }, [sidebarRef]);

  let City_Object = [];
  if (props.data.airtable.data.City_Object) {
    City_Object = props.data.airtable.data.City_Object.map(o => ({
      data: {
        ...o.data,
        Object_City: [Name],
        Place: [{ data: { Title: 'city' } }],
      },
    }));
  }

  const [selected, setSelected] = useState({ place: null, object: null });

  useEffect(() => {
    const [, , placename, objectname] = props.location.pathname.split('/');
    let selectedPlace = null;
    if (placename) {
      selectedPlace = City_Place.find(c => c.data.Slug === placename);
    }
    let selectedObject = null;
    if (selectedPlace && objectname) {
      selectedObject = selectedPlace.data.Object.find(o => o.data.Slug === objectname);
    }
    if (placename === 'city') {
      selectedObject = City_Object.find(c => c.data.Slug === objectname);
    }
    setSelected({ place: selectedPlace, object: selectedObject });
  }, [props.location.pathname]);

  let cityDescription = [];
  if (City_Description) {
    const englishDescription = City_Description.find(d => d.data.Language === 'English');
    if (englishDescription) cityDescription.push(englishDescription);
    cityDescription = [
      ...cityDescription,
      ...City_Description.filter(d => d.data.Language !== 'English'),
    ];
  }

  return (
    <Layout>
      {props.data ? (
        <>
          <SEO title={Name} />
          <Grid.Row
            className="flush"
            style={{ height: '45px', display: 'flex', alignItems: 'center', margin: '0rem 1rem' }}
          >
            <Breadcrumb pathname={pathname} places={City_Place} />
          </Grid.Row>
          <Grid.Row className="flush" style={{ height: 'calc(100vh - 80px - 45px)' }}>
            <Grid.Column className="sidebar" computer={6} mobile={16}>
              <Sidebar
                ref={sidebarRef}
                city={Name}
                cityDescription={
                  cityDescription.length
                    ? cityDescription
                        .map(d => d.data.Description)
                        .join('')
                        .replace(/\n\s*\n/gm, '</p><p>')
                        .split('</p>')
                        .filter(d => d !== '</p>')
                        .map(d => `${d}</p>`)
                    : null
                }
                cityObjects={City_Object}
                cityImage={
                  props.data.airtable.data.Image
                    ? props.data.airtable.data.Image.localFiles[0]
                    : null
                }
                places={City_Place}
                selectedPlace={selected.place}
                selectedObject={selected.object}
                // resetHandler={this.resetHandler}
              />
            </Grid.Column>
            <Grid.Column width={10} className="map" only="computer">
              <Atlas city={Name.toLowerCase()} places={City_Place} selectedPlace={selected.place} />
            </Grid.Column>
          </Grid.Row>
        </>
      ) : (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      )}
    </Layout>
  );
};

export default CityPage;

CityPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    airtable: PropTypes.shape({
      data: PropTypes.shape({
        Name: PropTypes.string,
        City_Place: PropTypes.arrayOf(PropTypes.shape()),
        City_Object: PropTypes.arrayOf(PropTypes.shape()),
        City_Description: PropTypes.arrayOf(PropTypes.shape()),
        Image: PropTypes.shape(),
      }),
    }),
  }),
};

CityPage.defaultProps = {
  data: {},
};

export const query = graphql`
  fragment thumbImage on File {
    childImageSharp {
      thumb: fluid(maxWidth: 60, maxHeight: 60, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment carouselImage on File {
    childImageSharp {
      carouselImage: fluid(maxWidth: 480, maxHeight: 480, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment fullImage on File {
    childImageSharp {
      fullImage: fixed(width: 1200) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  query cityPage($Name: String) {
    airtable(table: { eq: "Cities" }, data: { Name: { eq: $Name } }) {
      data {
        Name
        City_Description {
          data {
            Language
            Description
          }
        }
        Image {
          localFiles {
            ...carouselImage
            ...fullImage
          }
        }
        City_Object {
          data {
            Title
            Slug
            Source
            Copyright
            Image {
              localFiles {
                ...thumbImage
                ...carouselImage
                ...fullImage
              }
            }
            Object_Description {
              data {
                Language
                Description
              }
            }
            Objects_Relationships {
              id
              data {
                Title
                Slug
                Object_City
                Place {
                  id
                  data {
                    Title
                    Slug
                  }
                }
                Source
                Copyright
                Image {
                  localFiles {
                    ...thumbImage
                  }
                }
              }
            }
          }
        }
        City_Place {
          id
          data {
            Title
            Slug
            Latitude
            City {
              data {
                Name
              }
            }
            Latitude__Current_
            Longitude
            Longitude__Current_
            Places_Relationships {
              id
              data {
                Title
                Slug
                City {
                  data {
                    Name
                  }
                }
                Source
                Copyright
                Image {
                  localFiles {
                    ...thumbImage
                  }
                }
              }
            }
            Place_Description {
              data {
                Language
                Description
              }
            }
            Source
            Copyright
            Image {
              localFiles {
                ...thumbImage
                ...carouselImage
                ...fullImage
              }
            }
            Object {
              id
              data {
                Title
                Slug
                Object_City
                Place {
                  id
                  data {
                    Title
                    Slug
                  }
                }
                Objects_Relationships {
                  id
                  data {
                    Title
                    Slug
                    Object_City
                    Place {
                      id
                      data {
                        Title
                        Slug
                      }
                    }
                    Source
                    Copyright
                    Image {
                      localFiles {
                        ...thumbImage
                      }
                    }
                  }
                }
                Object_Description {
                  data {
                    Language
                    Description
                  }
                }
                Source
                Copyright
                Image {
                  localFiles {
                    ...thumbImage
                    ...carouselImage
                    ...fullImage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
