import React from 'react';
import PropTypes from 'prop-types';
import { List, Image, Grid, GridColumn, Card } from 'semantic-ui-react';
import InternalLink from '../InternalLink/InternalLink';

const ObjectList = ({ object, place, displayAsCard, displayCity }) => {
  const items = object || place;
  if (displayAsCard) {
    return (
      <Card.Group itemsPerRow={2}>
        {items &&
          items.map(o => {
            let placeLink = o;
            if (object) {
              if (o.data.Place) [placeLink] = o.data.Place;
              else placeLink = 'city';
            }
            return (
              <Card key={o.id}>
                <InternalLink
                  city={
                    object
                      ? o.data.Object_City[0].toLowerCase()
                      : o.data.City[0].data.Name.toLowerCase()
                  }
                  place={placeLink}
                  object={object ? o : null}
                >
                  {o.data.Image &&
                    o.data.Image.localFiles &&
                    o.data.Image.localFiles[0].childImageSharp && (
                      <Image
                        fluid
                        src={o.data.Image.localFiles[0].childImageSharp.carouselImage.src}
                      />
                    )}
                  <Card.Content>
                    <div style={{ margin: '10px', color: 'rgba(0, 0, 0, 0.7)' }}>
                      {o.data.Title}
                    </div>
                  </Card.Content>
                </InternalLink>
              </Card>
            );
          })}
      </Card.Group>
    );
  }
  return (
    <List selection size="large">
      {items &&
        items.map(o => {
          let placeLink = o;
          if (object) {
            if (o.data.Place) [placeLink] = o.data.Place;
            else placeLink = 'city';
          }
          const city = object ? o.data.Object_City[0] : o.data.City[0].data.Name;

          if (city) {
            return (
              <List.Item key={o.data.Title}>
                <InternalLink
                  city={city.toLowerCase()}
                  place={placeLink}
                  object={object ? o : null}
                >
                  <List.Content>
                    <Grid>
                      <Grid.Row>
                        <GridColumn width={1} />
                        <Grid.Column width={3} verticalAlign="middle">
                          {o.data.Image &&
                            o.data.Image.localFiles &&
                            o.data.Image.localFiles[0].childImageSharp && (
                              <Image
                                circular
                                src={o.data.Image.localFiles[0].childImageSharp.thumb.src}
                              />
                            )}
                        </Grid.Column>
                        <Grid.Column width={11} verticalAlign="middle">
                          <List.Description>
                            {displayCity ? (
                              <>
                                <b>{city}</b>
                                <br />
                                <span>{o.data.Title}</span>
                              </>
                            ) : (
                              <span>{o.data.Title}</span>
                            )}
                          </List.Description>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </List.Content>
                </InternalLink>
              </List.Item>
            );
          }
          return null;
        })}
    </List>
  );
};

ObjectList.propTypes = {
  object: PropTypes.arrayOf(PropTypes.shape()),
  place: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  displayAsCard: PropTypes.bool,
  displayCity: PropTypes.bool,
};

ObjectList.defaultProps = {
  object: null,
  place: null,
  displayAsCard: false,
  displayCity: false,
};

export default ObjectList;
