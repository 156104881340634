import React from 'react';
import PropTypes from 'prop-types';

import Details from './Details/Details';
import ItemList from './ItemList/ItemList';

import './Sidebar.css';

const Sidebar = React.forwardRef((props, ref) => {
  const {
    places,
    city,
    selectedPlace,
    selectedObject,
    cityDescription,
    cityObjects,
    cityImage,
  } = props;

  if (selectedPlace || selectedObject) {
    return (
      <div ref={ref}>
        <Details selectedPlace={selectedPlace} selectedObject={selectedObject} cityName={city} />
      </div>
    );
  }
  return (
    <div ref={ref}>
      <ItemList
        places={places}
        city={city}
        cityImage={cityImage}
        cityDescription={cityDescription}
        cityObjects={cityObjects}
      />
    </div>
  );
});

Sidebar.propTypes = {
  places: PropTypes.arrayOf(PropTypes.object).isRequired,
  city: PropTypes.string.isRequired,
  selectedPlace: PropTypes.shape(),
  selectedObject: PropTypes.shape(),
};

Sidebar.defaultProps = {
  selectedPlace: null,
  selectedObject: null,
};

export default Sidebar;
