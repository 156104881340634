import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

const ImageLightbox = ({ images, setLightboxOpen }) => {
  const [lightboxIndex, setLightboxIndex] = useState(0);
  return (
    <Lightbox
      mainSrc={images[lightboxIndex]}
      nextSrc={images[(lightboxIndex + 1) % images.length]}
      prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
      onCloseRequest={() => setLightboxOpen(false)}
      onMovePrevRequest={() => {
        setLightboxIndex((lightboxIndex + images.length - 1) % images.length);
      }}
      onMoveNextRequest={() => {
        setLightboxIndex((lightboxIndex + 1) % images.length);
      }}
    />
  );
};

ImageLightbox.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setLightboxOpen: PropTypes.func.isRequired,
};

export default ImageLightbox;
