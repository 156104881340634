import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, List, Segment, Image, Grid } from 'semantic-ui-react';
import Img from 'gatsby-image';

import ObjectList from '../ObjectList/ObjectList';
import InternalLink from '../InternalLink/InternalLink';
import Lightbox from '../Lightbox/Lightbox';
import DescriptionAccordion from '../DescriptionAccordion/DescriptionAccordion';

const ItemList = ({ places, city, cityImage, cityDescription, cityObjects }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  return (
    <>
      <Header style={{ margin: '1.4rem 0rem 1.2rem 0rem' }} as="h2">
        {city}
      </Header>
      <Segment style={{ marginTop: 10 }}>
        {cityImage && (
          <div
            role="button"
            tabIndex={-1}
            key={cityImage.childImageSharp.fullImage.src}
            onClick={() => setLightboxOpen(true)}
            onKeyPress={() => setLightboxOpen(true)}
          >
            <span
              style={{
                backgroundColor: 'black',
                opacity: '0.6',
                padding: '5px',
                lineHeight: '1px',
                borderRadius: '100%',
                width: '23px',
                position: 'absolute',
                zIndex: '10',
                right: '20px',
                top: '20px',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="expand-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M212.686 315.314L120 408l32.922 31.029c15.12 15.12 4.412 40.971-16.97 40.971h-112C10.697 480 0 469.255 0 456V344c0-21.382 25.803-32.09 40.922-16.971L72 360l92.686-92.686c6.248-6.248 16.379-6.248 22.627 0l25.373 25.373c6.249 6.248 6.249 16.378 0 22.627zm22.628-118.628L328 104l-32.922-31.029C279.958 57.851 290.666 32 312.048 32h112C437.303 32 448 42.745 448 56v112c0 21.382-25.803 32.09-40.922 16.971L376 152l-92.686 92.686c-6.248 6.248-16.379 6.248-22.627 0l-25.373-25.373c-6.249-6.248-6.249-16.378 0-22.627z"
                  className=""
                />
              </svg>
            </span>
            <Img
              fluid={cityImage.childImageSharp.carouselImage}
              objectPosition="50% 50%"
              style={{ cursor: 'pointer', marginBottom: '1.2rem' }}
            />
            {lightboxOpen && (
              <Lightbox
                images={[cityImage.childImageSharp.fullImage.src]}
                setLightboxOpen={setLightboxOpen}
              />
            )}
          </div>
        )}
        {cityDescription && <DescriptionAccordion description={cityDescription} />}
        {cityObjects && <ObjectList object={cityObjects} />}
      </Segment>
      <h3 style={{ margin: '35px 0px 20px 0px' }}>Places in this city</h3>
      {places.map(place => {
        const { data } = place;
        return (
          <Segment key={data.Title} padded>
            <List selection size="huge">
              <List.Item>
                <InternalLink place={place} city={city}>
                  <List.Content>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={3} verticalAlign="middle">
                          {data.Image &&
                            data.Image.localFiles &&
                            data.Image.localFiles[0].childImageSharp && (
                              <Image
                                circular
                                src={data.Image.localFiles[0].childImageSharp.thumb.src}
                              />
                            )}
                        </Grid.Column>
                        <Grid.Column width={13} verticalAlign="middle">
                          <List.Header>{data.Title}</List.Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </List.Content>
                </InternalLink>
              </List.Item>
            </List>
            {data.Object && <ObjectList place={place} object={data.Object} />}
          </Segment>
        );
      })}
    </>
  );
};

ItemList.propTypes = {
  places: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  city: PropTypes.string.isRequired,
  cityImage: PropTypes.shape(),
  cityDescription: PropTypes.shape(),
  cityObjects: PropTypes.shape(),
};

ItemList.defaultProps = {
  cityImage: null,
  cityDescription: null,
  cityObjects: null,
};

export default ItemList;
